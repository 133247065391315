import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

interface PlaceOrderResponse {
    success: boolean;
    data: {
        integrationType: 'postMessage' | 'redirect' | 'quotation';
        options: any;
        sawListUuid?: string;
    }
}

export const checkoutApi = createApi({
    reducerPath: 'checkoutApi',
    tagTypes: [ 'Checkout', 'SawList' ],
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.GATSBY_MICROSITE_API_URL
    }),
    endpoints: build => ({
        placeOrder: build.mutation<PlaceOrderResponse, any>({
            query: ({ retailer, locale, sawListId, metadata = {} }) => ({
                url: 'order/place',
                method: 'POST',
                body: { retailer, langCode: locale, sawListId, metadata }
            }),
            invalidatesTags: () => [ 'SawList' ]
        })
    })
});

export const {
    usePlaceOrderMutation
} = checkoutApi;