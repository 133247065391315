import React from 'react';
import { Box, Typography } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import { useIsLoading } from '@hooks/catalog';
import { useProductDimensionsSkeleton } from '@hooks/skeleton/catalog';

export default ({ length, width, withCm = true }) => {
    if (useIsLoading())
        return useProductDimensionsSkeleton();

    if (!length && !width)
        return;

    return (
        <Box sx={ { mt: 1 } } className="product-dimensions-container">
            <Typography sx={ { fontSize: '0.75rem' } } className="product-dimensions-gamma">
                max&nbsp;
                <Typography component="span" className="product-sizes">
                    { length } x { width }
                </Typography>
                { withCm &&
                    <Typography component="span" sx={ { pl: 0.5, fontSize: 'inherit' } }>
                        { useMicrocopy().get('catalog.filter.dimension.units_of_length', 'cm') }
                    </Typography>
                }
            </Typography>
        </Box>
    );
};
