import React, { useState } from 'react';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { InputLabel, Typography, Stack } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMicrocopy } from '@hooks/common';
import { useCatalogContext } from '@hooks/catalog';

const SortingFilter = ({ values, selected, handleChange }) => {
    const microcopy = useMicrocopy(),
        { catalogV2 } = useCatalogContext(),
        [ isSelectOpen, setSelectOpenState ] = useState(false);

    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent={ { xs: 'space-between', md: 'flex-start' } }
            gap={ catalogV2 ? 1.5 : 0 }
            className="sorting-filter"
        >
            {catalogV2 &&
            <Typography className="sorting-filter-main-title">
                { microcopy.get('catalog.filter.sorting', 'Sorting') }:
            </Typography>
            }
            <FormControl className="sorting">
                { !catalogV2 &&
                    <InputLabel id="sorting-select-label">
                        { microcopy.get('catalog.filter.sorting', 'Sorting') }
                    </InputLabel>
                }
                <Select
                    labelId="sorting-select-label"
                    id="sorting-select"
                    value={ selected }
                    label={ catalogV2 ? null : microcopy.get('catalog.filter.sorting', 'Sorting') }
                    onChange={ event => handleChange(event.target.value) }
                    onOpen={ () => { setSelectOpenState(true); } }
                    onClose={ () => { setSelectOpenState(false); } }
                    IconComponent={ () => isSelectOpen ? <ExpandLessIcon/> : <ExpandMoreIcon/> }
                    sx={ { minWidth: 160 } }
                    MenuProps={ {
                        autoFocus: false,
                        disableAutoFocusItem: true,
                        disableEnforceFocus: true,
                        disableAutoFocus: true,
                        className: `menu-popover ${catalogV2 ? 'v2': ''}`
                    } }
                >
                    { Object.keys(values).map(key =>
                        (<MenuItem key={ `sorting_value_${ key }` } value={ values[key] }>{ key }</MenuItem>))
                    }
                </Select>
            </FormControl>
        </Stack>
    );
};

export default SortingFilter;
