import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '@store';
import { prepareRetailerName, SawListHelper } from '@helpers';

export const baseApi = createApi({
    reducerPath: 'baseApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.GATSBY_MICROSITE_API_URL,
        prepareHeaders: (headers, { getState }) => {
            const app = (getState() as RootState).app;
            headers.set('Accept-Retailer', prepareRetailerName(app.retailer));
            headers.set('Accept-Language', app.locale);
            headers.set('uuid', SawListHelper.getSawListUid(app.retailer));
        },
    }),
    endpoints: () => ({}),
});
