import { createTheme } from '@mui/material';
import GoogleSans from '@fonts/ProductSans-Regular.woff';
import { createFontFamily } from 'themes/base/base';

let theme: any = createTheme({
    palette: {
        background: {
            default: '#fafafa',
            paper: '#fafafa',
        }, // @ts-ignore
        colors: {
            white: '#fff',
            paper: '#1d1d1d',
            tag: '#e6db74',
            punctuation: '#f8f8f2',
            attrName: '#a6e22e',
            attrValue: '#e6db74',
            operator: '#f92672',
            info: '#1976d2'
        },
    },
    typography: {
        ...createFontFamily('GoogleSans', {
            fontSize: 16,
            h4: {
                fontSize: '2rem',
            },
            h5: {
                fontSize: '1.5rem',
            },
        }),
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 440,
            md: 680,
            lg: 991,
            xl: 1336,
        },
    }
});

theme = createTheme(theme, {
    components: {
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                  font-family: 'GoogleSans';
                  src: url('${ GoogleSans }') format('woff');
                }
                body { -webkit-overflow-scrolling:touch }
                ::-webkit-scrollbar { 
                    WebkitAppearance: none; 
                    width: 10px; 
                    height: 10px; 
                }
                ::-webkit-scrollbar-thumb {
                    background-color: rgba(0,0,0,0.5);
                    border-radius: 5px;
                    border: 2px solid #eeeeee;
                }
                ::-webkit-scrollbar-track {
                    background-color: #eeeeee;
                    border-radius: 10px;
                }
                ${ theme.breakpoints.down('md') } {
                 html {
                    font-size: 0.95rem;
                 }
                }
                ${ theme.breakpoints.down('sm') } {
                 html {
                    font-size: 0.85rem;
                 }
                }
                @keyframes translate-y {
                  0% {
                    transform:translateY(0px);
                  }
                  50% {
                    transform:translateY(5px);
                  }
                  100% {
                    transform:translateY(0px);
                  }
                }
                @keyframes translate-x {
                  0% {
                    transform:translateX(0px);
                  }
                  50% {
                    transform:translateX(5px);
                  }
                  100% {
                    transform:translateX(0px);
                  }
                }
            `
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    background: theme.palette.colors.white,
                    maxWidth: 920,
                    margin: '0 auto',

                    'code': {
                        fontSize: '0.9rem',

                        [theme.breakpoints.down('md')]: {
                            wordBreak: 'break-all'
                        }
                    },

                    'iframe': {
                        transform: 'scale(1)',
                        transformOrigin: '0 0',
                    },

                    '.code_tag': {
                        color: theme.palette.colors.tag
                    },
                    '.code_punctuation': {
                        color: theme.palette.colors.punctuation
                    },
                    '.code_attr-name': {
                        color: theme.palette.colors.attrName
                    },
                    '.code_attr-value': {
                        color: theme.palette.colors.attrValue
                    },
                    '.code_operator': {
                        color: theme.palette.colors.operator
                    }
                }
            },
        },
        MuiPaper: {
            styleOverrides: {
                outlined: {
                    position: 'relative'
                },
            },
            variants: [
                {
                    props: { 'className': 'code' },
                    style: {
                        background: theme.palette.colors.paper,
                        position: 'relative',

                        '&:hover': {
                            '.MuiIconButton-root': {
                                color: theme.palette.colors.info
                            }
                        },

                        '.MuiIconButton-root': {
                            transition: 'color 0.1s ease'
                        }
                    }
                }
            ],
        },
        MuiAccordion: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                    background: theme.palette.colors.white
                }
            },
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    '&.available-retailers': {
                        borderBottom: `1px solid ${ theme.palette.divider }`,
                        paddingBottom: 20,
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            flexDirection: 'column',
                            paddingBottom: 0,
                        },
                        '.MuiTabPanel-root': {
                            paddingRight: 0,
                            minHeight: 475,
                            [theme.breakpoints.down('md')]: {
                                padding: 4,
                                minHeight: 320,
                            },
                            '.MuiList-root': {
                                borderLeft: `1px solid ${ theme.palette.divider }`,
                                height: '100%',
                                [theme.breakpoints.down('md')]: {
                                    borderTop: `1px solid ${ theme.palette.divider }`,
                                    borderLeft: 0,
                                    paddingTop: 20,
                                },
                                '.MuiListItem-root': {
                                    color: theme.palette.primary.main,
                                },
                                '.MuiListItemButton-root': {
                                    '.MuiListItemIcon-root': {
                                        minWidth: 'auto',
                                    },
                                    '&:hover': {
                                        '.MuiListItemText-root': {
                                            textDecoration: 'underline',
                                        },
                                    },
                                },
                            },
                        },
                        '.available-retailers-tab-list': {
                            display: 'flex',
                            color: theme.palette.primary.main,
                            [theme.breakpoints.down('md')]: {
                                height: 48,
                            },
                            '.MuiTabs-root': {
                                alignItems: 'center',
                                '.Mui-selected, .MuiTab-root:hover': {
                                    background: 'rgba(0, 0, 0, 0.03)',
                                    fontWeight: 600,
                                },
                            },
                            '.MuiTabs-scroller': {
                                flex: '1 1 0',
                            },
                            '.MuiTabs-scrollButtons': {
                                animation: 'translate-y 2s infinite',
                                [theme.breakpoints.down('md')]: {
                                    animation: 'translate-x 2s infinite',
                                },
                                '&:hover': {
                                    animation: 'none',
                                    background: 'rgba(0, 0, 0, 0.04)',
                                },
                                '&.Mui-disabled': {
                                    animation: 'none',
                                    opacity: 0.3,
                                    color: 'rgba(0, 0, 0, 0.5)'
                                },
                                borderRadius: '50%',
                                width: 32,
                                height: 32,
                                margin: '0 auto',
                            },
                        },
                    },
                },
            },
        },
    }
});

export default theme;
