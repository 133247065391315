import React from 'react';
import { Divider, Stack, Typography, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { ReactComponent as AddServiceIcon } from '@images/add_service_icon.svg';
import CheckCircleIcon from './CheckCircleIcon';

interface ProductUSPProps {
    deliveryMessage?: string;
    addServiceMessage?: string;
    addServiceURL?: string;
}

const ProductUSP = ({ deliveryMessage, addServiceMessage, addServiceURL }: ProductUSPProps) => (
    <>

        { deliveryMessage &&
            <Box className="product-usp-delivery-message" sx={ { mb: 2 } }>
                { deliveryMessage.split(';').map((message, index) => (
                    <Stack
                        key={ `delivery_message_${ index }` }
                        direction="row"
                        alignItems="center"
                        className="delivery-info"
                    >
                        <CheckCircleIcon className="delivery-icon"/>

                        <Typography>{ message }</Typography>
                    </Stack>
                )) }
            </Box>
        }

        { addServiceMessage &&
            <Stack direction="row" alignItems="center" className="service-info">
                { deliveryMessage && <Divider orientation="vertical" sx={ { display: 'none' } }/> }
                { addServiceURL
                    ? <>
                        <AddServiceIcon className="service-icon"/>
                        <a
                            href={ addServiceURL }
                            target="_blank"
                            onClick={ event => event.stopPropagation() }
                        >
                            <Typography>{ addServiceMessage }</Typography>
                        </a>
                    </>
                    : <>
                        <CheckIcon className="service-icon"/>
                        <Typography>{ addServiceMessage }</Typography>
                    </>
                }
            </Stack>
        }
    </>
);

export default ProductUSP;
