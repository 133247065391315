import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { useIsLoading, useCatalogContext } from '@hooks/catalog';
import { LazyLoadImage, RetailerLogo } from '@components/Common';

interface ProductImageProps {
    image: string,
    title: string,
    logo: string,
    promoLabel?: string,
    promoLabel2?: string,
    discountLabel?: any
}

const ProductImage = ({
    image,
    title,
    logo,
    promoLabel,
    promoLabel2,
    discountLabel,
}: ProductImageProps) => {
    const { catalogV2 } = useCatalogContext();

    return (
        <Box>
            <LazyLoadImage src={ image } alt={ title } height={ catalogV2 ? 260 : 200 }/>
            { !useIsLoading() &&
                <>
                    <Stack className="product-card-labels">
                        <Stack className="product-card-banners">
                            { promoLabel &&
                                <Typography variant="caption" className="promo-label">
                                    { promoLabel }
                                </Typography>
                            }

                            { promoLabel2 &&
                                <Typography variant="caption" className="promo-label">
                                    { promoLabel2 }
                                </Typography>
                            }

                            { !catalogV2 && discountLabel }
                        </Stack>

                        <RetailerLogo logo={ logo }/>
                    </Stack>
                    { catalogV2 && discountLabel }
                </>
            }
        </Box>
    );
};


export default ProductImage;
