import React from 'react';
import { LazyLoadImage as Image } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import { RetailerComponent } from '@components';
import { useProductImageSkeleton } from '@hooks/skeleton/catalog';
import { useIsLoading } from '@hooks/catalog';

export interface LazyLoadImageProps {
    src?: string,
    alt: string,
    height: number,
}

const LazyLoadImage = ({ src, alt, height = 192 }: LazyLoadImageProps) => {
    const isLoading = useIsLoading();

    return (
        (isLoading || src === null)
            ? useProductImageSkeleton(height)
            : <Image
                src={ src }
                alt={ alt }
                placeholder={ useProductImageSkeleton(height) }
                effect="opacity"
                width="100%"
                height={ height }
                style={ { objectFit: 'cover' } }
            />
    );
}

export default (props: LazyLoadImageProps) =>
    <RetailerComponent
        props={ props }
        component={ {
            dirname: 'Common',
            name: 'LazyLoadImage',
            element: LazyLoadImage,
        } }
        skeleton={ useProductImageSkeleton(props.height) }
    />;
