import React from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';
import { Options } from '@contentful/rich-text-react-renderer';
import { Typography } from '@mui/material';
import { ContentfulRichText } from '@models';

interface RichTextProps {
    value?: ContentfulRichText;
    className?: string;
    renderOptions?: Options;
}

const RichText = ({ value, className, renderOptions = {} }: RichTextProps) => (
    <Typography component="div" className={ className }>
        { value ? renderRichText(value, renderOptions) : null }
    </Typography>
);

export default RichText;
