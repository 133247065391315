import React from 'react';

interface CatalogFilterContextProps {
    hasSelectedValues: boolean;
    setHasSelectedValues: React.Dispatch<boolean>;
}

const CatalogFilterContext = React.createContext<CatalogFilterContextProps>({
    hasSelectedValues: false,
    setHasSelectedValues: value => {},
});

interface CatalogFilterProviderProps {
    data: CatalogFilterContextProps;
    children: React.ReactNode;
}

export const CatalogFilterProvider = ({ data, children }: CatalogFilterProviderProps) => {
    return (
        <CatalogFilterContext.Provider value={ data }>
            { children }
        </CatalogFilterContext.Provider>
    );
};

export const useCatalogFilterContext = () => {
    const context = React.useContext(CatalogFilterContext);

    if (context === undefined)
        throw new Error('useCatalogFilterContext must be inside a Filter/FilterV2 of CatalogPage');

    return context;
};
