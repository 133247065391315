import React, { useState } from 'react';
import { InputLabel, FormControl, Slider, OutlinedInput, Stack, Typography } from '@mui/material';
import { useMicrocopy } from '@hooks/common';
import { useDimensions } from '@hooks/catalog';
import { useCatalogFilterContext } from '@context/CatalogFilterContext';

const DimensionsFilterV2 = ({ onChange, maxDimensions }) => {
    const
        microcopy = useMicrocopy(),
        { dimensions } = useDimensions(),
        { setHasSelectedValues } = useCatalogFilterContext(),
        maxLength = maxDimensions?.configurator_input_max_length / 10,
        maxWidth = maxDimensions?.configurator_input_max_width / 10;

    const
        [ length, setLength ] = useState(dimensions['length'] ? dimensions['length'] / 10 : 0),
        [ width, setWidth ] = useState(dimensions['width'] ? dimensions['width'] / 10 : 0);

    React.useEffect(() => {
        if (!width && !length) return;

        setHasSelectedValues(!!width || !!length);
        handleDimensions();
    }, [ width, length ]);

    const
        checkLength = () => length > maxLength && maxLength != 0,
        checkWidth = () => width > maxWidth && maxWidth != 0;

    const ErrorMessage = ({ value }) => (
        <Typography className="error-message">
            { microcopy.get('catalog.filter.max_dimensions', 'Max is') }
            <Typography component="span" sx={ { px: 0.4, fontSize: 10 } }>{ value }</Typography>
            { microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
        </Typography>
    );

    const onSliderLengthChange = (event: Event, newValue: number | number[]) => {
        setLength(newValue as number);
    };
    const onSliderWidthChange = (event: Event, newValue: number | number[]) => {
        setWidth(newValue as number);
    };

    const onInputLengthChange = (newValue: string) => {
        const value = Number(newValue) || 0;
        setLength(value > maxLength ? maxLength : value);
    };
    const onInputWidthChange = (newValue: string) => {
        const value = Number(newValue) || 0;
        setWidth(value > maxWidth ? maxWidth : value);
    };

    const handleDimensions = () => {
        onChange({ length, width });
    };

    return (
        <Stack className="dimension-filter filter-content" gap={ 2.5 }>
            <Stack>
                <Typography className="filter-main-title">
                    { microcopy.get('catalog.filter.dimension.length_title', 'Length') }
                </Typography>
                <Stack direction="row" gap={ 3.75 }>
                    <Slider
                        aria-labelledby="length-dimension-slider"
                        getAriaLabel={ () => 'length-dimension-slider' }
                        min={ 0 }
                        max={ maxLength }
                        value={ length }
                        onChange={ onSliderLengthChange }
                        disableSwap
                        marks={ [
                            { value: 0, label: <Typography>{ 0 }</Typography> },
                            { value: maxLength, label: <Typography>{ maxLength }</Typography> },
                        ] }
                    />
                    <FormControl variant="outlined" aria-label="dimension-length">
                        <InputLabel htmlFor="outlined-adornment-length">
                            { microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
                        </InputLabel>
                        <OutlinedInput
                            type="number"
                            id="outlined-adornment-length"
                            value={ length }
                            name="dimension-length"
                            label={ microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
                            aria-describedby="outlined-length-helper-text"
                            aria-label="dimension-length"
                            inputProps={ { 'aria-label': 'dimension-length' } }
                            onChange={ event => onInputLengthChange(event.target.value) }
                            error={ length > maxLength }
                        />
                        { checkLength() && <ErrorMessage value={ maxLength }/> }
                    </FormControl>
                </Stack>
            </Stack>
            <Stack>
                <Typography className="filter-main-title">
                    { microcopy.get('catalog.filter.dimension.width_title', 'Width') }
                </Typography>
                <Stack direction="row" gap={ 3.75 }>
                    <Slider
                        aria-labelledby="width-dimension-slider"
                        getAriaLabel={ () => 'width-dimension-slider' }
                        min={ 0 }
                        max={ maxWidth }
                        value={ width }
                        onChange={ onSliderWidthChange }
                        disableSwap
                        marks={ [
                            { value: 0, label: <Typography>{ 0 }</Typography> },
                            { value: maxWidth, label: <Typography>{ maxWidth }</Typography> },
                        ] }
                    />
                    <FormControl variant="outlined" aria-label="dimension-weight">
                        <InputLabel htmlFor="outlined-adornment-weight">
                            { microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
                        </InputLabel>
                        <OutlinedInput
                            type="number"
                            id="outlined-adornment-weight"
                            value={ width }
                            name="dimension-width"
                            label={ microcopy.get('catalog.filter.dimension.units_of_length', 'cm') }
                            aria-describedby="outlined-weight-helper-text"
                            aria-label="dimension-weight"
                            inputProps={ { 'aria-label': 'dimension-weight' } }
                            onChange={ event => onInputWidthChange(event.target.value) }
                            error={ width > maxWidth }
                        />
                        { checkWidth() && <ErrorMessage value={ maxWidth }/> }
                    </FormControl>
                </Stack>
            </Stack>
        </Stack>
    );
};

export default DimensionsFilterV2;
