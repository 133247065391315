import React from 'react';
import { PriceFilterControls } from '@components/Catalog/Filters';
import { useCatalogFilterContext } from '@context/CatalogFilterContext';

const PriceFilter = ({ min, max, minValue, maxValue, onChange }) => {
    const
        isMinActive = Boolean(minValue),
        isMaxActive = Boolean(maxValue);

    const
        [ minPriceValue, setMinPriceValue ] = React.useState(minValue ?? min),
        [ maxPriceValue, setMaxPriceValue ] = React.useState(maxValue ?? max),
        [ minValueChanged, setMinValueChanged ] = React.useState(false),
        [ maxValueChanged, setMaxValueChanged ] = React.useState(false);

    const { setHasSelectedValues } = useCatalogFilterContext();

    const handleSliderChange = (event: Event, value: number | number[], activeThumb: number) => {
            switch (activeThumb) {
                case 0:
                    setMinPriceValue(value[activeThumb]);
                    setMinValueChanged(true);
                    break;
                case 1:
                    setMaxPriceValue(value[activeThumb]);
                    setMaxValueChanged(true);
                    break;
            }
        },
        applySliderChange = () => onChange(
            minValueChanged ? minPriceValue : undefined,
            maxValueChanged ? maxPriceValue : undefined
        );

    React.useEffect(() => {
        setMinPriceValue(isMinActive ? minPriceValue : min);
        setMinValueChanged(isMinActive);
    }, [ min, isMinActive ]);

    React.useEffect(() => {
        setMaxPriceValue(isMaxActive ? maxPriceValue : max);
        setMaxValueChanged(isMaxActive);
    }, [ max, isMaxActive ]);

    React.useEffect(() => {
        setHasSelectedValues(true);
    }, [])

    return (
        <PriceFilterControls
            minPriceValue={ minPriceValue }
            maxPriceValue={ maxPriceValue }
            onMinPriceChange={ value => setMinPriceValue(value) }
            onMaxPriceChange={ value => setMaxPriceValue(value) }
            min={ min }
            max={ max }
            onSliderChange={ handleSliderChange }
            onChangeCommitted={ applySliderChange }
        />
    );
};

export default PriceFilter;
